:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 1199px;
	--primary: #B7C9D4;
	--secondary: #FBC9A4;
	--light-orange: #F8F5CF;
	--lightblue: #e9eff6;
	--lightb: #e6f9ff;
	--black: #605B54;
	--white: #ffffff;
	--bodyfont: 'Poppins', sans-serif;
	--grey: #4e4e4e;
	--light-grey: #f5f5f5;
	--d-grey: #919191;
	--darkgrey: #666666;
	--black2: #333333;
	--green: #01bd86;
	--black3: #14141f;
	--grey2: #b7b7b7;
	--red: #ff0000;
	--grey3: #8a8a8a;
	--black4: #484848;
	--darkblue: #0d2e53;
	--black5: #4d4d4d;

	/*Calendar colors*/
	--not_available: #ff8888;
	--disabled: #b1f1c5;
	--available: #b1f1c5;
	--selected: #104277;
	--selection_range: #6395FC;
	--selected_border: #fff;
	--text_color: #333;
	--about-us-grid-width: 250px;
}