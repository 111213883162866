@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.search-form {
	background-color: var(--primary);
	color: var(--white);
	padding: 105px 0 0;

	@media (max-width:1200px) {
		padding-top: 0;
	}

	.form-group {
		>label {
			font-weight: normal;
			font-size: 13px;
			color: var(--white);
		}

		#region-trigger {
			background-color: var(--white);
		}
	}




	.label-li {
		label {
			color: var(--grey);
		}
	}

	.asd__wrapper--full-screen {
		@media (max-width: 992px) {
			padding-top: 0;

		}


	}


}



.sidebar-search {
	background-color: var(--white);
	margin-bottom: 30px;

	.widgt-title {
		text-transform: uppercase;
		margin-bottom: 10px;
		display: block;
		color: var(--black);
		font-weight: 600;
		position: relative;

		a {
			float: right;
			text-transform: none;
			font-weight: 500;
			position: absolute;
			right: 0;
			width: auto;
			top: 0;
		}
	}

	.label-li {
		li {
			margin-bottom: 8px;

			.checkbox {
				margin: 0;
				font-size: 13px;
				color: var(--black5);
			}
		}
	}

	.form-control {
		margin-bottom: 10px;
		width: 100%;
		text-align: left !important;
	}
}

.available-dates {
	padding: 0 25px;
	margin-bottom: 15px;

	.owl-dt-bl {
		text-align: center;

		.dt-box {
			font-size: 13px;
			color: var(--white);
			letter-spacing: 1px;
			font-weight: bold;
			background-color: var(--secondary);
			padding: 5px;
			text-transform: uppercase;
		}

		.dt-cont-bl {
			padding: 30px 10px;
			background-color: var(--white);

			h3 {
				font-size: 19px;
				margin-bottom: 0;
				font-weight: 600;
			}
		}
	}

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		button {
			position: absolute;
			left: 0;
			color: #a4a4a4;

			span {
				font-size: 28px !important;
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;
			}
		}
	}
}

.search-about-bl {
	background-color: var(--white);
	margin-bottom: 25px;

	.srch-bl-title {
		border-bottom: 1px solid #E5E5E5;
		padding: 10px 15px;
		text-transform: uppercase;
		font-weight: 600;
		margin: 0;
	}

	.srch-ab-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 15px;
		flex-wrap: wrap;
	}

	.search-rest-left {
		h3 {
			font-size: 25px;
			font-weight: 400;
			margin: 0 0 5px;
		}

		span {
			font-size: 15px;
			color: var(--darkgrey);
			margin-bottom: 5px;
		}
	}
}


.unit-srch-li {
	.results {
		>.row {
			margin: 0;
		}
	}

	.overflowscrolls {
		overflow: inherit;
	}
}


.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.result-pane {
		width: 100%;
		padding: 3px;

		color: #565a5c;

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0 5px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.result-pane {
			display: none;
		}
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;


		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.results,
	.spinner-panel,
	.more-btn-panel {
		background-color: #f5f5f5;
	}

	.more-btn-panel {
		padding-bottom: 40px;
		margin: 0 auto;
		margin-top: 50px;

		@media (min-width:768px) {
			display: none;
		}
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
			color: red;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.unit-main-row {
			margin-bottom: 15px;

			.alternatives {
				padding: 15px;
				position: relative;
				display: block;
				background-color: #fff;
				border-top: 1px solid #e5e5e5;
				color: var(--black);

				.li-bullet {
					list-style: disc;
					padding-left: 18px;
				}
			}

			.offer-box {

				@media (max-width:767px) {
					display: block
				}

				.offer-left {


					@media (max-width:767px) {
						display: block;
						text-align: center;
						margin-right: 0;

					}
				}

				ul {
					padding: 0;
					list-style: none;
					margin: 0;

					li {
						margin: 0 0 5px;
						line-height: 14px;
						font-size: 14px
					}
				}
			}
		}

		.unit-row {
			width: 100%;
			position: relative;

			border-radius: 5px;

			@media (min-width:768px) {
				background-color: #fff;
				/* box-shadow: 0 2px 12px rgba(254, 154, 0, .25); */
			}

			.img-col {
				position: relative;
				border-radius: 5px;
				overflow: hidden;

				@media (max-width:767px) {
					box-shadow: 0 2px 12px rgba(254, 154, 0, .25);
				}
			}

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {
					>div {
						height: 100%;
					}

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: #fff;
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 15px;

				@media (max-width:767px) {
					margin: 0 15px;
					background-color: #fff;
					box-shadow: 0 2px 12px rgba(254, 154, 0, .25);
					top: -35px;
					border-radius: 5px;
				}

				.unit-btm-dt {
					padding-bottom: 14px;
					border-bottom: 1px solid #d6d6d6;
					margin-bottom: 14px;
				}

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.unit-title {
					font-size: 20px;
					font-weight: bold;
					color: var(--black);
					text-transform: uppercase;

					&:hover {
						color: var(--secondary);
					}
				}



				.unit-serv-li {
					margin-bottom: 5px;

					li {
						display: inline-block;
						font-size: 13px;
						color: #4A4A4A;
						width: 48%;
						margin-bottom: 10px;

						i {
							margin-right: 5px;
						}
					}
				}


				.unit-place {
					font-size: 13px;
					color: var(--black);

					i.fa {
						color: #868686;
						margin-right: 5px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}
			}

			.unit-price-col {

				text-align: right;

				@media (max-width:992px) {
					text-align: center;
				}


				.price-start {
					color: var(--darkgrey);
					display: block;
				}

				.prices {

					.rentRate {
						margin-bottom: 18px;
						display: block;

						span {
							color: var(--darkgrey);
						}

						strong {
							font-size: 22px;
							color: var(--black);
							line-height: 1;
						}

						.main-price {
							strong {
								text-decoration: line-through;
								color: var(--red);
							}
						}
					}

					.price {
						font-size: 20px;
					}
				}
			}
		}

		.unit-btm-dt {
			li {
				display: inline-flex;
				font-size: 14px;
				color: var(--darkgrey);
				width: max-content;
				margin-right: 20px;

				i {
					margin-right: 5px;
				}
			}
		}


	}


	.search-result-properties {
		display: block;

		li {
			font-size: 18px;
			margin-right: 5px;
			display: inline-block;
		}
	}

	.sort-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;

		.sort-by {

			display: flex;
			align-items: center;

			>.box {
				border: none !important;

				.arrow {
					z-index: 2;
				}

				>.body {
					padding: 0;

					.list-group {
						margin-bottom: 0;
					}
				}
			}

			strong {
				font-size: 14px;
				color: var(--grey3);
				margin-right: 15px;
			}

			.sort-item {
				color: var(--grey3);
				font-size: 12px;
				margin: 5px 0;
				background-color: var(--white);
				padding: 10px 12px;
				border: 1px solid #dcdcdc;
				margin-left: -1px;
				display: inline-block;
			}

		}

		.sort-rest {
			color: var(--grey3);
			font-weight: 600;
		}
	}





	.search-bar {
		padding: 14px;
		border-top: 1px solid var(--light-grey);

		.my-search-token {
			font-size: 12px;
			border-radius: inherit;
			background-color: var(--secondary);
			border: none;
			padding: 3px 6px;

			.close {
				color: var(--white) !important;
				opacity: 1;
				padding: 0 !important;
				text-shadow: none;
				font-size: 16px;
				position: relative !important;
				left: 2px;
				top: 1px;
				display: inline-block !important;
			}
		}

		&.srch-rest {
			.widt-title {
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 1px;
				margin-top: 0;
			}

			.rest-count {
				color: #a3a3a3;
				font-size: 13px;
				display: block;
				margin-bottom: 5px;
			}

			.text-link {
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 600;
			}
		}

		&:first-child {
			border-top: none !important;
		}



		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	.unit-act-row {
		.offer-bl-srch {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.offer-left {
				border-radius: 0;
				font-size: 13px;
				padding: 8px 16px;
				text-transform: uppercase;
				background-color: var(--primary);
				color: var(--white);
				border-color: var(--primary);

				&:hover {
					background-color: var(--secondary);
				}
			}
		}
	}

	@media (max-width:767px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;
			z-index: 50;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	@media (min-width:768px) {
		.map-pane {
			@mixin flex;

		}

		.result-pane {
			width: 750px;
			max-width: 60%;

			.more-btn-panel {
				display: none;
			}


		}


		.results {

			.unit-row {
				@mixin flexlayout row;

				.info-col {
					width: 50%;
				}

				.unit-price-col {

					position: relative;

					.unit-act-row {

						a.btn-book {
							position: absolute;
							bottom: 15px;
							z-index: 2;
							width: calc(100% - 30px);
						}
					}
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						position: absolute;
						@mixin size100;


						.full-img {
							height: 100%;
							object-fit: cover;

						}
					}

				}

			}
		}



	}



	.toggle-button {
		display: none;

		@media (max-width:992px) {
			position: fixed;
			top: 50vh;
			display: block;
			background-color: var(--secondary);
			padding: 8px 15px;
			z-index: 50;
			left: 0;
			color: var(--white);
			font-size: 24px;
		}
	}

	.sidebar-search {
		@media (min-width:993px) {
			display: block !important;
		}

		.search-m-form {

			@media (min-width:993px) {
				display: none;
			}

			@media (max-width:992px) {
				background-color: var(--darkblue);
				padding: 15px 0;
				position: relative;
				z-index: 3000;
			}
		}
	}
}

#searchpanel {
	.container {
		width: auto;
	}

	@media (min-width:993px) {
		position: relative;
		display: block;
		overflow: visible;

		.close {
			display: none;
		}

		&.modal {
			z-index: 3 !important;
		}

		&.fade {
			opacity: 1;
		}

		.modal-dialog {
			width: auto;
			-webkit-transform: translateY(0%);
			transform: translateY(0%);
		}
	}





	@media (max-width:992px) {
		.search-bar {
			border: 1px solid #eee;
			border-top: none;
		}

		&.modal.fade {
			.modal-dialog {
				-webkit-transform: translateX(-100%);
				transform: translateX(-100%);
				position: fixed !important;
			}

			&.in {
				.modal-dialog {
					-webkit-transform: translateX(0);
					transform: translateX(0);
				}
			}
		}
	}

	.modal-dialog {
		@media (max-width:992px) {
			margin: 0;
			padding: 50px 15px 15px;
			z-index: 20001;
			max-width: 370px;
			width: 100%;
			background-color: var(--white);
			overflow-y: scroll;
			height: 100vh;

			>.close {
				color: var(--fff);
				padding: 0 8px 1px;
				position: absolute;
				top: 6px;
				right: 16px;
				z-index: 50;
				opacity: 1;
				font-size: 36px;
				background-color: #ff9a00;
				background-color: var(--primary);
				line-height: 1;
			}
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			max-width: 650px;
		}
	}
}

.toggle-button2 {
	border-radius: 30px;
	margin-bottom: 30px;
	padding: 15px 30px 15px 30px;
	max-width: 238px;
	width: 100%;

}