.inner-banner {
	position: relative;
	overflow: hidden;
	height: 470px;

	@media (max-width:992px) {
		height: 320px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:after {
		height: 80px;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		display: block;
		background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
		background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
		content: "";
		opacity: 0.6;
	}

	.banner-text {
		padding: 40px;
		top: 90px;
		position: absolute;
		left: 0;
		background: rgb(255, 255, 255);
		background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
		width: 80%;

		p {
			margin-bottom: 0;
		}
	}

	.container {
		position: relative;
	}
}

.page-header {
	h1 {
		position: relative;
		/*top: 13px;*/

		small {
			font-family: var(--d-font);
			font-size: 18px;
			color: #ccc;
			position: relative;
			top: 0;
		}
	}
}

.single-unit {
	.img-content {
		height: 260px;
		overflow: hidden;

		display: block;

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.unit-title {
		font-size: 18px;
		color: #000;
		color: var(--black);

		&:hover {
			color: var(--primary);
		}
	}
}

.facity-heading {
	padding-top: 20px;
	padding-bottom: 10px;
	background-color: var(--primary);
}

.featur-bl {
	border: 1px solid #ddd;
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
	border-radius: 10px;
	overflow: hidden;

	@media (max-width:992px) {
		margin-bottom: 25px;
	}

	img {
		width: 100%;
	}
}


.form-strip {
	.contact-form {


		label {
			color: var(--black);
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			margin-bottom: 0;
			padding-bottom: 10px;

			&:before {
				top: 2px;
			}
		}

		.checkbox {
			label {
				text-transform: inherit;
				font-weight: normal;
			}

			a {
				color: var(--black);
				font-size: 14px;
			}
		}

		.conact-btn {
			width: 100%;
			bottom: 0;
			margin-top: 35px;

			.btn {
				padding: 11px 30px 15px;
				box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
				font-size: 20px;

				@media (max-width:992px) {
					font-size: 18px;
				}
			}
		}

		.form-control {
			border: none;
			border-bottom: 2px solid #f2f2f2;
			text-transform: uppercase;
			margin-bottom: 30px;
			border-radius: 0;
			box-shadow: inherit;
			padding: 0;
			background-color: transparent;
		}
	}

	.unit-map {
		height: 400px;
		width: 100%;

		@media (max-width:992px) {
			margin-bottom: 20px;
		}
	}
}

.facility-view {
	.contact-form {
		h2 {
			margin-top: 0;
		}

		.btn-block {
			font-size: 16px !Important;
		}
	}

}

.white-sp {
	white-space: normal;
	height: auto !important;
}

.unit-cont-bl {
	border: 1px solid #e8e8e8;
	border-top: none;
	padding: 20px;
}

.slide-single-unit {
	margin-bottom: 20px;

	.image-block {
		position: relative;

		img {
			object-fit: cover;
			object-position: center;
			height: 260px;
			width: 100%;
		}

		.fav-icon {
			position: absolute;
			top: 15px;
			right: 15px;
			color: var(--primary);
			font-size: 20px;
		}
	}

	.unit-cont-home {
		border: 1px solid #e8e8e8;
		border-top: none;
		padding: 20px;

		.unit-address {
			color: var(--grey2);
			position: relative;
			padding-left: 20px;

			i {
				position: absolute;
				left: 0;
				top: 3px;

			}
		}

		.ratings {
			margin-bottom: 15px;
			min-height: 20px;

			i.fa {
				color: var(--primary);
			}
		}

		.unit-detail {
			margin-bottom: 25px;

			li {
				margin-right: 15px;
				display: inline-block;
				color: var(--grey2);

				i {
					margin-right: 6px;

				}
			}
		}

		.rent-rate {
			position: relative;
			padding-right: 125px;
			min-height: 40px;

			h3 {
				display: inline-block;
				margin-bottom: 0;
			}

			.btn {
				position: absolute;
				right: 0;
				top: 5px;
			}
		}
	}

	.unit-title {
		color: var(--black3);
		min-height: 45px;

		&:hover {
			color: var(--primary);
		}
	}
}

.facility-units {
	.description {
		margin-bottom: 30px;
	}

	.fact-unit-bl {
		.row {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.unit-cont-home {
		.unit-title {
			font-size: 20px;
			font-weight: 700;
			color: var(--secondary);
			text-transform: uppercase;

			h3 {
				font-size: 20px;
				color: var(--black);
				font-weight: 700;
				text-transform: uppercase;
				margin-bottom: 0;

				&:hover {
					color: var(--secondary);
				}

				@media (max-width: 991px) {
					font-size: 16px;
				}
			}
		}

		.unit-place {
			font-size: 13px;
			color: var(--black);

			i.fa {
				color: #868686;
				margin-right: 5px;
			}
		}
	}

	.unit-serv-li {

		margin-top: 20px;

		li {
			display: inline-block;
			font-size: 13px;
			color: #4a4a4a;
			width: 48%;
			margin-bottom: 10px;

			i {
				margin-right: 5px;
			}

		}
	}

	.unit-detail {
		margin-bottom: 0 !important;

		li {

			display: inline-flex;
			font-size: 14px;
			color: var(--darkgrey);
			width: max-content;
			margin-right: 20px;
			margin-bottom: 10px;

			span {
				color: var(--darkgrey);
			}


			i {
				margin-right: 5px;
			}
		}
	}
}