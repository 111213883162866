.static {
	display: block;
}

/* about us pagee */
.about-us-v1 {
	margin-top: var(--page-margin-top);

	/*	.inner-banner {
		background-image: url(RESOURCE/img/bg-wir.jpg);
	}  */

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member {
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		border: 1px solid #ddd;
		border-radius: 4px;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		-webkit-box-pack: center;
		justify-content: center;
		min-height: 140px;
		padding: 0 1em;

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}

.region-view {
	@media (max-width:992px) {
		padding-top: 40px;
	}

	@media (min-width:1200px) {
		margin-top: 100px;
	}
}

.hansapark-page {
	.inner-banner {
		background-image: url(RESOURCE/img/hansapark_heroimg.jpg);
	}
}

.arborea-page {
	.inner-banner {
		background-image: url(RESOURCE/img/arborea_heroimg.jpg);
	}

	.flex-row-reverse {
		flex-wrap: wrap;
	}
}

.inner-banner {
	background-image: url(RESOURCE/img/bg-banner-contact.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 470px;
	position: relative;

	@media (max-width:992px) {
		height: 470px;

	}

	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}
	}
}

.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		.team-dt {
			border: 1px solid #e8e8e8;
			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}

.ct-form-section {
	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;

		a {
			background: var(--primary);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondary);
			}
		}
	}
}