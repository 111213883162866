@import "setup.css";

.DiscoverVacation {

	.spots-area {

		@media (max-width:767px) {
			.col-xs-6 {
				min-height: 270px;
			}
		}
	}
}