.main-footer {
	/*background: var(--light-grey);*/
	border-top: 1px solid #c6c6c6;
	/*background: rgb(0, 73, 155);
	background: linear-gradient(90deg, #919191 100%, rgba(245, 245, 245, 1) 40%);*/
	/*margin-top: 50px;*/
	//background-color: #f5f5f5;
	background-color: #B7C9D4;
	padding: 50px 0;

	@media (min-width:993px) {
		margin-top: 0;
		/*background: rgb(0, 73, 155);
		background: linear-gradient(90deg, #919191 50%, rgba(245, 245, 245, 1) 40%);*/
	}

	@media (max-width:992px) {
		padding: 10px 0;
	}

	.container {
		position: relative;

		.foot-contact-us {
			position: absolute;
			right: 0;
			z-index: 5;
			top: -25px;

			.btn {
				font-size: 12px;
				padding: 15px 30px;
				border-top: 1px solid #c6c6c6;
				border-radius: 30px;
				padding-right: 58px;
				position: relative;

				i {
					margin-left: 15px;
					font-size: 22px;
					position: absolute;
					top: 12px;
				}
			}

			.btn-call {
				background-color: #c9c9c9;
				padding: 7px;
				margin-left: 15px;
				float: right;
				border-radius: 50%;
				position: relative;
				top: -10px;

				&:after {
					content: "";
					background: var(--light-grey);
					display: block;
					height: 45px;
					position: absolute;
					top: 35px;
					z-index: 0;
					left: 0;
					width: 100%;

				}

				@media (max-width:992px) {
					&:after {
						background: var(--d-grey);
					}


				}

				.btn-icon-call {
					width: 52px;
					height: 52px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					background-color: var(--white);
					border-radius: 50%;
					font-size: 18px;
					color: var(--primary);
					box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
					position: relative;
					z-index: 1;
				}
			}


		}
	}

	.social-links {
		margin-top: 30px;

		a {
			background-color: #919191;
			margin-right: 4px;
			border: 1px solid var(--primary);
			font-size: 16px;
			width: 32px;
			height: 32px;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			&:hover {
				background-color: var(--black);
			}

			&.fa {
				color: #fff;
				font-weight: 400;

			}
		}

		/*	@media (max-width:992px) {
				a {
					color: var(--primary);

					&:hover {
						background-color: var(--primary);
						color: var(--white);
					}
				}


			}*/
	}

	.footer-left {

		padding-left: 0;
		color: var(--white);

		p {}

		@media (max-width:992px) {
			padding: 30px 0;
		}

		.ft-logo {
			margin-bottom: 15px;
		}

		p {
			color: var(--white);
			font-size: 15px;
		}

		@media (max-width:992px) {
			p {
				/*color: var(--black);*/
			}
		}





	}

	.similar-col {
		@media (min-width:993px) {
			display: flex;
		}
	}

	.widget-bl {
		margin: 0 0 30px;

		.newsletter-form {
			position: relative;

			button {

				color: #0f4277;
				;
				border: none;
				border-left: 1px solid var(--primary);
				background-color: transparent;
				font-size: 20px;
				position: absolute;
				right: 0;
				margin: 8px;
				padding-left: 11px;
				top: 0;


			}
		}

		.widget-title {
			margin: 0 0 15px;
			color: var(--black2);
			letter-spacing: 1px;
			text-transform: uppercase;
		}

		ul {
			padding: 0;

			li {
				list-style: none;
				margin-bottom: 8px;

				a {
					color: var(--darkgrey);
					font-size: 13px;

					&:hover {
						color: var(--light-orange);
					}
				}
			}
		}
	}

	.footer-copyright {
		color: #c2c2c2;
		position: absolute;
		bottom: 50px;
		left: 15px;
		font-size: 13px;

		@media (max-width:992px) {
			left: 0;
			bottom: 0;
			position: relative;
			width: auto;
			margin-bottom: 0;
			padding: 10px 0;
		}
	}

	.hide-label {
		display: none;
	}


}

.paymentmethods {
	display: flex;
	align-items: center;

	.fa-brands {
		font-size: 30px;
		margin-right: 5px;

		img {
			width: 75px;
			margin-top: -4px;
		}

	}
}