@import "setup.css";

.indexpage {
	/* margin-top: 80px; */

	@media (min-width:1201px) {
		margin-top: 80px;
	}

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.box {
		.asd__wrapper {
			@media (max-width: 480px) {
				margin: 0 auto;
				padding-top: 0;
			}
		}
	}

	.banner-forms {
		position: relative;
		min-height: 560px;
		/* z-index: 3; */

		.search-form-area {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: var(--white);
			border-bottom: 1px solid #eaeaea;
			flex-flow: wrap;
			box-shadow: 0 2px 12px rgba(254, 154, 0, .25);
			border-radius: 5px;

			.form-flex {
				width: 31%;
				padding: 20px 22px;
				border-right: 1px solid #f4f4f4;

				@media (max-width:992px) {
					width: 100%;
					border-bottom: 1px solid #f4f4f4;
					border-right: none;
					padding: 6px 12px;
				}


				.form-bx-area {
					position: relative;

				}

				.fa {
					position: absolute;
					right: 15px;
					top: 17px;
				}

				/*
				.fa {
					position: absolute;
					right: 0;
					top: 6px;
				}
           */

				.form-control {
					padding: 15px;
					background-color: var(--white);
					border-radius: 5px;
				}


				/*
				.form-control {
					padding: 5px 0;
					background-color: var(--white);
					border: none;
					padding-right: 20px;
				}
           */

				>label {
					color: var(--black);
					font-size: 13px;
					text-transform: uppercase;
					display: block;
					font-weight: normal;
					letter-spacing: 1.5px;

					@media (max-width:992px) {
						display: none;
					}
				}
			}

			.form-btn {
				width: 7%;
				display: flow-root;
				align-items: center;

				@media (max-width:992px) {
					width: 100%;
				}

				.btn {
					width: 101%;
					height: 92px;
					align-items: center;
					display: flex;
					justify-content: center;
					font-size: 28px;
					border-radius: 0 5px 5px 0;

					@media (max-width:992px) {
						height: 50px;
						font-size: 20px;
						width: 100%;
						border-radius: 0px 0px 5px 5px;
					}
				}
			}
		}
	}

	.banner {

		min-height: 560px;
		position: relative;
		z-index: -1;



		.intro {
			position: relative;
			z-index: 3;

			.hero {
				color: #fff;
				background: none;

				margin-bottom: 30px;
				padding-bottom: 0px;
				padding-top: 100px;


				h1 {
					font-size: 72px;
					font-weight: 600;
					margin: 30px 0 0;
					display: inline-block;
					padding-top: 15px;
					text-transform: uppercase;
					color: var(--whitecolor);

					@media (max-width:992px) {
						font-size: 40px;
						margin-top: 0;
					}

					@media (max-width:550px) {
						font-size: 36px;
					}
				}



			}

			.btn {
				min-width: 170px;
			}

		}



	}

	.banner-slide {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;

		.owl-item {
			position: relative;


		}

		.item {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			display: flex;
			align-items: center;
			/*padding: 0 15px;*/
			width: 100%;
			justify-content: center;
			min-height: 670px;

			&.slide1 {
				background-image: url(RESOURCE/img/slide1.webp);
			}

			&.slide2 {
				background-image: url(RESOURCE/img/slide2.webp);
			}

			&.slide3 {
				background-image: url(RESOURCE/img/slide3.webp);
			}

			&.slide4 {
				background-image: url(RESOURCE/img/slide4.webp);
			}

			&.slide5 {
				background-image: url(RESOURCE/img/slide5.webp);
			}

			&.slide6 {
				background-image: url(RESOURCE/img/slide6.webp);
			}

			&.slide7 {
				background-image: url(RESOURCE/img/OZ_indexBanner007.jpg);
			}

			&.slide8 {
				background-image: url(RESOURCE/img/OZ_indexBanner008.jpg);
			}

			&.slide9 {
				background-image: url(RESOURCE/img/slide09.jpg);
			}

			&.slide10 {
				background-image: url(RESOURCE/img/slide10.jpg);
			}

		}

		.owl-nav {
			position: absolute;
			top: 40%;
			left: 3%;
			transform: rotateZ(90deg);
			margin-top: -35px;

			button {
				color: var(--white);
				font-size: 24px;
				width: 20px;
			}

			@media (max-width:992px) {
				display: none;
			}
		}

		.owl-dots {
			position: absolute;
			top: 50%;
			left: 4%;
			margin-top: -35px;

			@media (max-width:992px) {
				display: none;
			}


			.owl-dot {
				width: 3px;
				height: 3px;
				background-color: var(--white);
				margin-bottom: 25px;
				display: inherit;
				border-radius: 50%;
				position: relative;


				&.active {
					span {
						width: 20px;
						height: 20px;
						border: 1px solid #fff;
						display: inline-block;
						position: absolute;
						border-radius: 50%;
						left: -9px;
						top: -8px;

						&:after {
							content: "";
							width: 46px;
							height: .5px;
							background-color: #fff;
							background-color: var(--white);
							display: inline-block;
							position: absolute;
							top: 8px;
						}
					}
				}
			}
		}
	}



	.searchbar {
		position: absolute;
		width: 100%;
		bottom: -40px;
		max-width: inherit !important;

		@media (max-width:992px) {
			bottom: 0px;
		}

		.row {
			margin: 0;
		}

		.searchbox {

			.form-control {
				height: 40px;
			}

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}

		@media (min-width:768px) {
			max-width: 650px;
		}

		@media (min-width:993px) {
			max-width: 950px;
		}

	}

	.contact-section {
		background-color: #fff !important;
	}




	@media (max-width:992px) {
		.banner {
			.intro {
				.hero {
					padding-top: 170px;
				}
			}
		}
	}

	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;
			height: 100vh;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}

		}
	}

	.logo-image-bl {
		padding: 30px;
		text-align: center;
		display: block;
	}
}

@media (max-width:992px) {
	.asd__wrapper--full-screen {
		padding-top: 60px;
	}

	.box.full-xxs .guestbox {
		/* margin-top: 55px; */
	}
}