.booking-view {
	@media (max-width:992px) {
		margin-top: 55px;
	}

	.btn-primary {
		white-space: break-spaces;
	}

	.page-header {
		margin-top: 0;
	}


	.cart-details {
		padding: 14px;
		background-color: var(--white);
		margin-top: 60px;

		@media (max-width:992px) {
			margin-top: 30px;
		}

		.unit-details {
			margin: 20px 0;
			color: var(--grey);

			li {
				padding-left: 30px;
				padding-right: 15px;
				margin-top: 5px;
				position: relative;

				&:before {
					content: "\f00c";
					font-family: 'Font Awesome 5 Pro';
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--primary);
				}
			}
		}

		.price {
			color: var(--primary);
		}
	}

	.price-table {
		.service-title {

			.btn-default,
			.btn {
				display: none !important;
			}

		}
	}

	.pd-form {


		label {
			font-weight: 500;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			label {
				font-weight: 700;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
	}


}

.amount-options-list {
	margin: 20px 0;

	.amount-option-btn {
		.amount-or {
			text-align: center;
			margin: 15px 0;
			width: 100%;
			max-width: 305px;
		}

		.btn-default {
			width: 100%;
			max-width: 305px;
		}
	}
}