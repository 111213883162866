@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Light.eot');
	src: url('RESOURCE/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Light.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Light.svg#Poppins-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

.input-group .form-control {
	position: relative;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
	margin-bottom: 0;
}

.popover {
	color: var(--black);
}

.input-group {
	position: relative;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	align-items: stretch;
	width: 100%;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Medium.eot');
	src: url('RESOURCE/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

.indexpage {
	.tab-content {
		background-color: transparent !important;
	}
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Regular.eot');
	src: url('RESOURCE/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-SemiBold.eot');
	src: url('RESOURCE/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Bold.eot');
	src: url('RESOURCE/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}

.pagination-panel {
	text-align: center;
}

@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}

a,
a:hover,
a:focus {
	outline: none;
}

@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

.dropdown-toggle:after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;

	@media (max-width:1200px) {
		position: absolute;
		right: 10px;
		top: 17px;
	}

}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}

.wrap-btn {
	white-space: inherit;
	height: auto !important;
}

.asd__mobile-close {
	color: var(--white);
	position: absolute;
	top: 6px;
	right: 16px;
	z-index: 50;
	opacity: 1;
	font-size: 24px;
	background-color: #000;
	background-color: var(--black);
	line-height: 1;
	padding: 3px 10px;
}

.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.well {
	background: var(--white);
}

.bg-lightblue {
	background: var(--lightblue);
}

.map {
	height: 300px;
}

.checkbox label {
	vertical-align: text-top;
}

.img-responsive {
	display: inline-block;
}

.hidden {
	display: none;
}

.h-100 {
	height: 100% !important;
}

.map-dialog {

	.modal-header {
		border: none;
		padding: 0;
	}

	.close {
		position: absolute;
		z-index: 5;
		background: var(--primary);
		right: -15px;
		top: -15px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		margin: 0;
		opacity: 1;
		text-shadow: none;
		color: var(--white);
		text-align: center;
		font-weight: normal;

		&:hover {
			background: var(--secondary);
		}
	}

}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

ul.points {
	margin: 0px;
	padding: 0 0 0 15px;

	li {
		list-style-type: none;
		display: block;
		line-height: 36px;


	}

	:before {
		font-family: 'Font Awesome 5 Pro';
		content: "\f00c";
		padding-right: 10px;
		font-weight: normal;
		color: var(--secondary);
	}
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.m-0 {
	margin: 0 !important;
}

.m-1 {
	margin: .25rem !important;
}

.m-2 {
	margin: .5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.mx-0 {
	margin-left: 0 !immortant;
	margin-right: 0 !immortant;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.mt-1 {
	margin-top: .25rem !important;
}

.mr-1 {
	margin-right: .25rem !important;
}

.mb-1 {
	margin-bottom: .25rem !important;
}

.ml-1 {
	margin-left: .25rem !important;
}

.mx-1 {
	margin-left: .25rem !important;
	margin-right: .25rem !important;
}

.my-1 {
	margin-top: .25rem !important;
	margin-bottom: .25rem !important;
}

.mt-2 {
	margin-top: .5rem !important;
}

.mr-2 {
	margin-right: .5rem !important;
}

.mb-2 {
	margin-bottom: .5rem !important;
}

.ml-2 {
	margin-left: .5rem !important;
}

.mx-2 {
	margin-right: .5rem !important;
	margin-left: .5rem !important;
}

.my-2 {
	margin-top: .5rem !important;
	margin-bottom: .5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mr-3 {
	margin-right: 1rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.ml-3 {
	margin-left: 1rem !important;
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.my-3 {
	margin-bottom: 1rem !important;
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mr-4 {
	margin-right: 1.5rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.ml-4 {
	margin-left: 1.5rem !important;
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mr-5 {
	margin-right: 3rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.ml-5 {
	margin-left: 3rem !important;
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}

.mr-auto {
	margin-right: auto !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ml-auto {
	margin-left: auto !important;
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.my-auto {
	margin-bottom: auto !important;
	margin-top: auto !important;
}

.p-0 {
	padding: 0 !important;
}

.p-1 {
	padding: .25rem !important;
}

.p-2 {
	padding: .5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.pt-1 {
	padding-top: .25rem !important;
}

.pr-1 {
	padding-right: .25rem !important;
}

.pb-1 {
	padding-bottom: .25rem !important;
}

.pl-1 {
	padding-left: .25rem !important;
}

.px-1 {
	padding-left: .25rem !important;
	padding-right: .25rem !important;
}

.py-1 {
	padding-top: .25rem !important;
	padding-bottom: .25rem !important;
}

.pt-2 {
	padding-top: .5rem !important;
}

.pr-2 {
	padding-right: .5rem !important;
}

.pb-2 {
	padding-bottom: .5rem !important;
}

.pl-2 {
	padding-left: .5rem !important;
}

.px-2 {
	padding-right: .5rem !important;
	padding-left: .5rem !important;
}

.py-2 {
	padding-top: .5rem !important;
	padding-bottom: .5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pr-3 {
	padding-right: 1rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pl-3 {
	padding-left: 1rem !important;
}

.py-3 {
	padding-bottom: 1rem !important;
	padding-top: 1rem !important;
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pr-4 {
	padding-right: 1.5rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pl-4 {
	padding-left: 1.5rem !important;
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}

.pr-5 {
	padding-right: 3rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}

.pl-5 {
	padding-left: 3rem !important;
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {

	.asd__day-button {
		pointer-events: auto;
	}


	.asd__day-button:disabled,
	.asd__day-button[disabled] {
		pointer-events: none;
	}

	.asd__day--not-available {
		background: var(--not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(135deg, var(--disabled) 50%, var(--not_available) 50%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--disabled) 50%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--selection_range) !important;
		color: white !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: var(--disabled) !important;
	}

	.asd__day--enabled {
		background-color: var(--available) !important;
		color: var(--text_color) !important;

		/*button {
			text-decoration: underline;
		}*/

		&:hover {
			background-color: var(--selected) !important;
			color: white !important;
		}



		&.asd__day--disabled {
			background-color: var(--disabled) !important;
			color: var(--text_color);
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--selected) !important;
			}

			&.asd__day--in-range {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
			}

			&.asd__day--hovered {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
			}
		}

		&.asd__day--arrival {
			&:hover {
				background-color: var(--selected) !important;
			}
		}

		&.asd__day--not-available {
			background: var(--not_available) !important;
			opacity: 1 !important;
		}
	}

	.asd__day--selected {
		background: var(--selected) !important;
		color: white !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;

		/*Arrow display css start*/
		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				left: 0px;
			}
		}

		/*Arrow display css end*/
	}

	.asd__month-table {
		.arrow {
			bottom: 0;
			left: 50%;
			margin-left: -5px;
			border-width: 5px 5px 0;
			position: absolute;
			width: 0;
			height: 0;
			border-color: transparent;
			border-style: solid;
			border-top-color: #000 !important;
		}

		.asd__week {
			display: flex;
		}
	}
}


.asd__month-name {
	font-size: 16px !important;
	color: #0d256e;
	font-weight: normal !important;
}

.asd__day-button {
	color: #455997;
}

.asd__change-month-button>button {
	border-radius: 50% !important;
	height: 36px;
}

.asd__change-month-button>button>svg {
	fill: var(--primary) !important;
	position: relative;
	top: 2px;

}


/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

a:focus,
.btn:focus,
span:focus,
button:focus,
label:before:focus,
.btn:focus {
	outline: 0 !important;
	text-decoration: none !important;
}

.checkbox input[type=checkbox]:focus+label:before {
	outline: inherit !important;
	outline: 0 !important;
}


button,
select {
	text-transform: none;
	outline: none;
}

@media (min-width: 1200px) {
	.d-xl-none {
		display: none !important;
	}
}

.nav-act .btn+.btn {
	margin-left: 10px;
}

.d-xl-none {}

.btn {
	border-radius: 0;
	font-size: 13px;
	padding: 8px 16px;
	text-transform: uppercase;

	&.btn-primary,
	&.btn-go {
		background-color: var(--primary);
		color: var(--white);
		border-color: var(--primary);

		&:hover {
			background-color: var(--secondary);
			border-color: var(--secondary);
		}
	}

	&.btn-default {
		color: var(--black);
		border-color: var(--primary);

		&.btn-sm {
			font-size: 12px;
		}

		&:hover {
			border-color: var(--primary);
			background-color: var(--primary);
			color: var(--white);
		}
	}

	&.btn-sm {
		font-size: 14px;
		padding: 6px 12px;
	}

	&.btn-secondary {
		background-color: var(--secondary);
		color: var(--black);
		border-color: var(--primary);

		i {
			color: var(--primary);
			margin-right: 5px;
		}

		&:hover {
			background-color: var(--primary);
			border-color: var(--primary);
			color: var(--white);

			i {
				color: var(--white);
			}
		}
	}

	&.btn-lg {
		height: 46px;
		font-size: 18px;
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}
}

.bl-center {
	display: flex;
	align-items: center;
}

.bl-space-betwen {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width:992px) {
		display: flex;
	}
}

body {
	font-family: var(--bodyfont);
	color: var(--black);
	font-size: 14px;
}

.container {
	@media (min-width: 1400px) {
		max-width: 1240px;
		width: 100%;
	}
}

p {
	font-weight: 300;
	line-height: 1.6;
}


a {
	&:hover {
		text-decoration: none;
	}
}

.form-control {
	border: 1px solid #e6e6e6;
	padding: 10px 15px;
	font-size: 13px;
	color: #565c5c;
	border-radius: 0;
	height: auto;
	box-shadow: inherit;
	text-align: left;

	&:focus {
		box-shadow: inherit;
	}
}

.nav-tabs {
	border-bottom: none;
	margin-bottom: 5px;



	li {
		&.active {
			a {
				background-color: var(--white);
				color: var(--secondary);
			}
		}

		a {

			background-color: #d8e6f5;
			color: rgba(0, 73, 155, .6);
			text-transform: uppercase;
			border-radius: 0;
			font-size: 13px;
			margin-right: 4px;
			font-weight: 600;
			padding: 10px 15px;

			@media (max-width:550px) {
				font-size: 10px;
				padding: 8px 10px;
			}
		}
	}
}

.tab-content {
	background-color: var(--white);
}

.pad60 {
	padding: 60px 0;

	@media (max-width:550px) {
		padding: 40px 0;
	}
}

.pad80 {
	padding: 80px 0;

	@media (max-width:550px) {
		padding: 60px 0;
	}
}

.p-0 {
	padding: 0 !important;
}

h2 {
	font-size: 36px;

	@media (max-width:992px) {
		font-size: 28px;
	}
}

h1,
h2,
h3,
h4 {
	font-weight: 600;
	margin-top: 0;
}

h1,
h2 {
	text-transform: uppercase;
	letter-spacing: 1.5px;
	margin: 0 0 15px;

}

ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
}

.pb-15 {
	padding-bottom: 15px;
}

.pb-30 {
	padding-bottom: 30px !important;
}

b,
strong {
	font-weight: 600;
}

.f16 {
	font-weight: bold;
}

.section-title {

	margin-bottom: 15px;

	&.center {
		text-align: center;
	}

	margin-bottom: 30px;

	.heading1 {
		font-size: 36px;
		display: inline-block;
		position: relative;
		padding-right: 55px;
		margin-bottom: 10px;

		@media (max-width:992px) {
			font-size: 28px;
		}

		padding-bottom:5px;
		border-bottom:1px solid var(--secondary);
	}


	.heading2 {
		font-size: 28px;
		display: block;
		position: relative;

		@media (max-width:992px) {
			font-size: 24px;
		}
	}

	.heading3 {
		font-size: 28px;
		display: inline-block;
		position: relative;
		padding-right: 55px;
		margin-bottom: 10px;

		@media (max-width:992px) {
			font-size: 24px;
		}

		padding-bottom:5px;
		border-bottom:1px solid var(--secondary);
	}

}

h3 {
	font-size: 20px;

	@media (max-width:992px) {
		font-size: 16px;
	}
}

h4 {
	font-size: 18px;

	@media (max-width:992px) {
		font-size: 14px;
	}
}

.big-para {
	line-height: 1.6;
	font-weight: normal;
	font-size: 16px;
}

.big-para-2 {
	font-size: 13px;
	font-weight: normal;
}

.mt-10 {
	margin-top: 10px;
}

.pt-50 {
	padding-top: 50px;
}

.bg-grey {
	background-color: var(--lightblue);
}

.li-grey {
	background-color: var(--light-grey);
}

.flex-row-reverse {
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.link {
	color: var(--primary);
	letter-spacing: 1px;
}

.search-form-area,
.search-bar,
.search-layout,
.search-form {
	.datepicker-trigger .asd__wrapper {
		@media (max-width:767px) {
			padding-top: 0;
		}
	}

	.datepicker-trigger .asd__wrapper,
	.box.full-xxs {
		@media (max-width:480px) {
			padding-top: 0;
			margin: 0 auto;
		}

		.body {
			padding-top: 30px;
		}

		.header {
			.close-btn {
				margin: 0;
				position: absolute;
				top: 5px;
				right: 5px;
				width: 20px;
				height: 20px;
				color: #fff !important;
				background-color: var(--primary);
				border-radius: 3px;
				font-size: 12px;
				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					background-color: var(--secondary);
				}

				.fa.fa-times {
					position: static !important;
					font-size: 12px !important;
					color: #fff !important;
				}
			}

		}


		@media (min-width:481px) {
			min-width: 320px;
		}
	}
}

.nxt-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;



		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border: 1px solid #ddd !important;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--white) !important;
			position: absolute;
			left: 0;

			span {
				font-family: 'Font Awesome 5 Pro';
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--primary);
					font-family: 'Font Awesome 5 Pro';
					position: relative;
					left: 2px;
				}
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;

				span {

					&:before {
						content: "\f061";
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f060";

					}
				}
			}
		}
	}
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pb-30 {
	padding-bottom: 30px;
}

.pull-sm-right {
	@media (min-width: 768px) {
		float: right;
	}
}

a {
	color: var(--primary);

	&:hover {
		color: var(--secondary);
	}
}

.breadcrumb {
	background-color: transparent;
	padding: 35px 0;
	margin-bottom: 0;

	.breadcrumb-item {
		padding-left: 8px;
		text-transform: uppercase;

		&:first-child {
			padding-left: 0;
		}

		a {
			font-size: 14px;
			text-transform: uppercase;
			color: var(--black4);

			i {
				margin-left: 8px;
			}

			&:hover {
				color: var(--primary);
			}
		}

		&.active {
			span {
				color: var(--primary);
				font-weight: bold;
			}
		}
	}
}

.breadcrumb>li+li:before {
	display: none;
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}

.checkbox label:after {
	padding-left: 2px !important;
	font-size: 12px !important;
	margin-left: -16px !important;
	padding-top: 1px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.checkbox {
	label {
		padding-left: 10px !important;
		line-height: 20px;

		&:before {
			width: 22px !important;
			height: 22px !important;
			border-color: #c6c6c6;
		}
	}
}

.tick-text {
	li {
		margin-bottom: 5px;

		.fa {
			color: var(--secondary);
			margin-right: 8px;
		}
	}
}

.btn-block+.btn-block {
	margin-top: 12px;
}

.pad40 {
	padding: 40px 0;
}

.min-row {
	margin: 0 -7px;

	.col-md-7,
	.col-md-5,
	.col-md-6,
	.col-md-8,
	.col-md-4,
	.col-md-9,
	.col-md-3,
	.col-sm-7,
	.col-sm-5 {
		padding: 0 7px;
	}
}

.form-horizontal {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}
}

.contact-bl {
	display: flex;
	margin-bottom: 15px;
	position: relative;
	padding-left: 60px;
	min-height: 40px;
	align-items: center;

	&:last-child {
		margin-bottom: 0;
	}

	a {
		color: var(--black);
	}


	i {
		color: var(--primary);
		background-color: var(--white);
		padding: 5px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 2px;
		box-shadow: 0 0 10px rgba(0, 0, 0, .18);
		font-size: 18px;
	}

	.fa-whatsapp {
		font-size: 25px;
		font-weight: 400;
	}
}

.aside-widget {
	padding: 20px 15px;
	border-bottom: 1px solid #e2e2e2;
	background-color: var(--light-grey);
	font-size: 13px;

	&:last-child {
		border-bottom: none;
	}
}



.three-units {
	display: flex;

	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
	}

	.unit-col {
		height: 100%;
	}

	.unit-row {
		display: none;

		&:first-child {
			display: block !important;
		}

		&:nth-child(2),
		&:nth-child(3) {
			display: block !important;
		}

		.slide-single-unit {
			height: 100%;
			background: #fff;
			/*	display: flex;
			flex-direction: column;
			justify-content: space-between;*/
			border: 1px solid #e8e8e8;

			.unit-cont-home {
				border: 0;
			}
		}
	}
}

.six-units {
	.col-md-4 {
		display: none;

		&:nth-child(-n+6) {
			display: block;
		}
	}
}

.pt-0 {
	padding-top: 0 !important;
}

.slide-single-unit {
	.unit-img {
		img {
			width: 100%;
		}
	}

	.unit-cont-home {
		background-color: var(--white);
	}
}

.hide-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.cetner-text {
	text-align: center;
}

.price-table {
	.btn {
		padding: 4px 10px;
		margin-right: 8px;

		&.btn-warning.btn-sm {
			font-size: 12px;
		}

	}
}

.asd__mobile-header h3 {
	color: var(--black);
}

.asd__mobile-close {
	background-color: var(--black);
}

.guestbox {
	.btn {
		width: 36px !important;
		height: 36px !important;
		font-size: 24px !important;
	}
}

.print-visible {
	display: none;
}

.mt-0 {
	margin-top: 0 !important;
}


.guestbox {
	label {
		font-size: 13px;
		margin-bottom: 0;
	}
}

.pad30 {
	padding: 30px 0;
}

.payment-view {
	padding-top: 30px;
	padding-bottom: 50px;

	.btn {
		box-shadow: initial;
	}
}

.unit-detail,
.search-result-properties,
.unit-btm-dt {
	.fa {
		font-family: 'Font Awesome 5 Pro' !important;
		font-weight: 400 !important;

		&.fa-bed:before {
			content: "\f236";
		}

		&.fa-compress-alt:before {
			content: "\f422";
		}
	}
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		max-width: 300px;
		margin: 35px auto 0 !important;
	}

	span {
		font-size: 15px;
		color: var(--black);

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;
		color: var(--black);

		@media (max-width: 767px) {
			margin: 0 0 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}

.blogheading {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 10px;
	line-height: 1.1;
}

.mt-20 {
	margin-top: 20px !important;
}

.pl-10 {
	padding-left: 10px;
}

.last.pl-10 {
	@media (max-width: 992px) {
		padding-left: 0;
	}
}


@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}


.fab {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 400;
}

.fa-arrows-h:before {
	content: "\f07e";
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	src: url('RESOURCE/fonts/fontawesome-webfont.eot?v=4.7.0');
	src: url('RESOURCE/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
		url('RESOURCE/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
		url('RESOURCE/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
		url('RESOURCE/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
		url('RESOURCE/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-brands-400.eot");
	src: url("RESOURCE/fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-brands-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-brands-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-brands-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.checkbox input[type=checkbox]:checked+label:after {
	content: "\f00c";
	font-family: Font Awesome\ 5 Pro !important;
	font-weight: 300;
}

#cboxOverlay {
	background: #000;
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	src: url('RESOURCE/fonts/FontAwesome5Pro-Regular.eot');
	src: url('RESOURCE/fonts/FontAwesome5Pro-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/FontAwesome5Pro-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/FontAwesome5Pro-Regular.woff') format('woff'),
		url('RESOURCE/fonts/FontAwesome5Pro-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/FontAwesome5Pro-Regular.svg#FontAwesome5Pro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.fa {
	&.fa-heart {
		font-weight: 300;

	}

	&.fa-heart-o {
		font-weight: 400;
	}
}



.fa,
.fas {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
}

.fal {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 400;
}

.far {
	font-family: 'Font Awesome 5 Pro';
	font-weight: normal;
}

.fab {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 400;
}



@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-solid-900.eot");
	src: url("RESOURCE/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-solid-900.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-solid-900.woff") format("woff"),
		url("RESOURCE/fonts/fa-solid-900.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-solid-900.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}